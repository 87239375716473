/* Apps.css */
.appiframecontainer {
  width: calc(100% - 56px) !important;
  height: calc(100% - 64px) !important;
}

/* Styles for the dashboard container */
.dashboard-container {
  background-color: #333;
  color: #fff;
  font-family: Arial, sans-serif;
}

/* Styles for the app bar */
.app-bar {
  background-color: #222;
}

/* Styles for the app bar title */
.app-bar-title {
  color: #fff;
}

/* Styles for the cool components container */
.cool-components {
  background-color: #333;
}

/* Styles for the grid items */
.grid-item {
  background-color: #222;
  border: 1px solid #333;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

/* Styles for the grid item titles */
.grid-item h3 {
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
}

/* Styles for the grid item descriptions */
.grid-item p {
  color: #ccc;
  font-size: 14px;
  margin-top: 5px;
}

/* Styles for the grid item icons */
.grid-item img {
  max-width: 100%;
  /* Prevent stretching */
  max-height: 100%;
  /* Prevent stretching */
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

/* Styles for buttons */
.button-primary {
  background-color: #cd6636;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button-primary:hover {
  background-color: #b55228;
}

.button-secondary {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button-secondary:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.card-link {
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
}

.premium-card {
  position: relative;
  width: 100%;
  max-width: 300px;
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  border: 2px solid transparent;
}

.card-image {
  height: 150px;
  background-size: contain;
  /* Prevent image stretching */
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  margin: 5px;
  border-radius: 10px;
}

.card-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  border-radius: 10px;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  color: #fff;
  text-align: center;
  max-height: 100px;
  overflow: hidden;
}

.card-title {
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  transition: color 0.2s ease-in-out;
}

.card-link:hover .premium-card {
  transform: scale(1.05);
  box-shadow: 0 0 10px 2px #cd6636;
}

.card-link:hover {
  transform: translateY(-5px);
}

.card-link:hover .card-title {
  color: #cd6636;
  transition: color 0.2s ease-in-out;
}