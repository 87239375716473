
.app-bar {
  background-color: #212121; /* Darker app bar background color */
}

.app-bar-title {
  font-size: 24px;
  font-weight: bold;
  color: #fff; /* White text color for app bar title */
}

.settings-options {
  margin-top: 20px;
}

.setting {
  margin-bottom: 16px;
}

.action-button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.website {
  /* Add premium styles for the website section here */
}

.cool-components {
  /* Add premium styles for cool components here */
}
/* Add these CSS rules to your Settings.css file */
.action-button .saving-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

