
/* Modify FirebaseUI styles */
/* Set primary button color to dark orange (#cd6636) */
.firebaseui-container {
    background-color: #333;
    /* Set dark background color */
    color: white;
    /* Set text color to white */
}

.firebaseui-input {
    background-color: #444;
    /* Set input background color */
    color: white;
    /* Set input text color */
    border-color: #555;
    /* Set input border color */
}

/* Customize the primary button color */
.firebaseui-id-submit {
    background-color: #cd6636;
    /* Set primary button background color to dark orange */
    color: white;
    /* Set primary button text color to white */
    border-color: #cd6636;
    /* Set primary button border color to dark orange */
}
