/* Dashboard.css */
.layout {
  transition: none!important;
}
.react-grid-item.cssTransforms {
  transition-property: none!important;
}
/* Background color for the entire dashboard in dark mode */
.dashboard-container {
  width: 100%;
  height: 100vh;
  background-color: #121212; /* Dark background color */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #fff; /* White text for dark mode */
}

/* App bar background color in dark mode */
.app-bar {
  background-color: #212121; /* Dark gray */
  margin: 0;
}

/* Tab container styles in dark mode */
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #333 transparent; /* Firefox */
  -webkit-scrollbar-width: thin; /* Webkit */
  -webkit-scrollbar-color: #333 transparent; /* Webkit */
  overflow-y: hidden; /* Remove vertical scrollbar */
  scroll-behavior: smooth; /* Add smooth scroll transition */
}

/* Tab styles in dark mode */
.tab {
  background-color: #333; /* Dark gray background for tabs */
  color: #fff; /* White text on dark background */
  padding: 8px 16px;
  border-radius: 10px 10px 0 0;
  margin-right: -2px;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  height: 38px;
  vertical-align: top;
  line-height: 38px;
  display: flex; /* Center the elements */
  align-items: center;
  justify-content: center;
}

/* Tab hover style in dark mode */
.tab:hover {
  background-color: #444; /* Slightly brighter background on hover */
}

/* Active tab style in dark mode */
.tab.active {
  background-color: #ff8c66; /* Accent color for the active tab */
  margin: 0;
}

/* Close icon style in dark mode */
.close-icon {
  margin-left: 10px;
  background-color: transparent;
  transition: background-color 0.3s;
  border-radius: 50%;
  padding: 5px;
}

/* Close icon hover style in dark mode */
.close-icon:hover {
  background-color: red; /* Red on hover */
}

/* Add icon style in dark mode */
.add-icon {
  font-size: 24px;
}

/* Search area styles */
.search-area {
  padding: 20px;
  display: flex;
  flex-direction: row; /* Change flex-direction to row */
  align-items: center;
}

/* Search input style in dark mode */
.search-input {
  border-radius: 10px 0 0 10px; /* Rounded left corners */
  background-color: #333; /* Dark gray for search input */
  color: #fff; /* White text for input */
  padding: 15px 20px;
  font-size: 18px;
  border: none;
  outline: none;
  transition: background-color 0.3s;
  flex-grow: 1; /* Allow input to grow and take available space */
  margin-right: -4px; /* Adjust for button spacing */
}

/* Load button style in dark mode */
.load-button {
  background-color: #ff4500; /* Accent color for the "Load" button */
  color: #fff; /* White text for the button */
  padding: 15px 20px;
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 0 10px 10px 0; /* Rounded right corners */
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}

/* Search input focus style in dark mode */
.search-input:focus {
  background-color: #444; /* Slightly brighter background on focus */
}

/* Load button hover style in dark mode */
.load-button:hover {
  background-color: #ff5722; /* Slightly brighter on hover */
}


/* Proxy browser styles */
.proxy-browser {
  width: 100%;
  height: 100%;
  background-color: #121212; /* Dark background color */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* Test button page styles */
.test-button-page {

  width: 100%;
  height: 100%;
  background-color: #121212; /* Dark background color */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
  border-radius: 50%; /* Make it circular */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  cursor: pointer;
  border: none; /* Remove border */
  outline: none; /* Remove outline */
  min-width:initial!important;
}

/* Back button hover style */
.back-button:hover {
  background-color: rgba(0, 0, 0, 0.9); /* Slightly darker on hover */
}


/* Backdrop styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* Semi-transparent black backdrop */
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* Hidden class */
.hidden {
  display: none !important;
}